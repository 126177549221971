import styled from "styled-components"
import { Menu, Paper } from "@material-ui/core"
import colors from "styles/colors"

const StyledPaper = styled(Paper)`
  && {
    background-color: ${colors.white};
    position: relative;
    min-height: 300px;
  }
`

const SettingLabel = styled.div`
  display: inline-block;
  margin: 8px 0 8px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  min-width: 120px;
`

const RowWrapper = styled.div`
  position: relative;
  display: block;
  height: 42px;
`

const HeaderWrapper = styled.div`
  display: block;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  position: relative;
`

const IconWrapper = styled.div`
  display: inline-block;
  margin-left: 26px;
  position: relative;
  width: 32px;
  height: 32px;
  top: 8px;
`

const FavoriteStarIconWrapper = styled(IconWrapper)`
  margin-left: 8px;
`

const ActionWrapper = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: "…";
    transform: rotate(90deg);
    color: black;
    position: relative;
    font-size: 20px;
    left: calc(50% - 2px);
    top: 7px;
    display: inline-flex;
  }
`

const ControlButtons = styled.div`
  display: inline-block;
  justify-content: flex-end;
  position: absolute;
  right: 35px;
  top: 10px;
`

const StyledEmptyText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledMenu = styled(Menu)`
  && {
    .MuiMenu-paper {
      min-width: 100px;
      border-radius: 4px;
      background: white;
      color: black;
    }
  }
`

const StyledSortableHandle = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  cursor: grab;
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
`

export {
  StyledPaper,
  StyledMenu,
  StyledSortableHandle,
  StyledEmptyText,
  FavoriteStarIconWrapper,
  SettingLabel,
  ControlButtons,
  ActionWrapper,
  RowWrapper,
  HeaderWrapper,
  IconWrapper,
}
