import React, { useEffect, useState } from "react"
import { LinearProgress } from "@material-ui/core"
import styled from "styled-components"
import { observer } from "mobx-react"

const StyledProgressBar = styled(LinearProgress)`
  margin-bottom: 10px;

  && {
    .MuiLinearProgress-root {
      border-radius: 8px;
      background-color: green;
      height: 4px;
    }

    .MuiLinearProgress-bar {
      background-color: ${({ customcolor }) => customcolor};
      border-radius: 8px;
    }
  }
`

const ProgressBarLabel = styled.div`
  && {
    color: ${({ color }) => color};
    margin-bottom: 10px;
  }
`

const LogsStatus = ({ status }) => {
  const [statusObject, setStatusObject] = useState(null)
  const generateValueAndColor = (status) => {
    const colorsAndLabels = [
      { color: "#333333", statusName: "SENDING", label: "Sending" },
      {
        color: "#FF2D00",
        statusName: "CANCELLED",
        label: "Cancelled by system",
      },
      {
        color: "#FF8C00",
        statusName: "RECEIVED",
        label: "Received",
      },
      { color: "#FF2D00", statusName: "FAILED", label: "Failed" },
      { color: "#43A047", statusName: "APPLIED", label: "Applied" },
    ]
    const findInArray = colorsAndLabels.find((obj) => obj.statusName === status)
    const findIndex = colorsAndLabels.findIndex(
      (obj) => obj.statusName === status
    )

    if (findIndex === -1) return
    return {
      value: ((findIndex + 1) / colorsAndLabels.length) * 100,
      color: findInArray.color,
      label: findInArray.label,
    }
  }

  useEffect(() => {
    setStatusObject(generateValueAndColor(status))
  }, [setStatusObject, status])

  if (statusObject) {
    return (
      <>
        <ProgressBarLabel color={statusObject.color}>
          {statusObject.label}
        </ProgressBarLabel>
        <StyledProgressBar
          style={{ backgroundColor: "rgba(51,51,51,0.16)" }}
          variant="determinate"
          value={statusObject.value}
          customcolor={statusObject.color}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default observer(LogsStatus)
