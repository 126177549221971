import { types } from "mobx-state-tree"

const Log = types
  .model("Log", {
    id: types.identifier,
    message: types.maybe(types.string),
    createdAt: types.maybeNull(types.string),
    payload: types.frozen(),
    type: types.string,
    notes: types.maybeNull(types.string),
    status: types.maybeNull(
      types.enumeration([
        "SENDING",
        "RECEIVED",
        "APPLIED",
        "CANCELLED",
        "FAILED",
      ])
    ),
  })
  .views((self) => {
    return {}
  })

export default Log
