import React from "react"
import styled from "styled-components"

const NotesWrapper = styled.div`
  color: black;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 0;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`

const TitleStyle = styled.span`
  font-weight: 600;
`;

const Notes = ({ id, text }) => {
  if (text) {
    return (
      <>
        <NotesWrapper>
          <TitleStyle>Notes:</TitleStyle> {text}
        </NotesWrapper>
      </>
    )
  } else {
    return <></>
  }
}

export default Notes
