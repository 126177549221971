import { types, getEnv, flow, getRoot } from "mobx-state-tree"
import Categories from "models/categories/categories"
import { omit } from "lodash"
import EargoGenerationService from "../../services/eargo_generation_service"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const accountId = new URL(window.location.href).searchParams.get("accountId")
const CUSTOMER_PATH = `/v1/php/customers/${accountId}`

const JournalStore = types
  .model("JournalStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    categories: types.optional(types.map(Categories), {}),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      getCategory(id) {
        return self.isLoaded ? self.categories.get(id) : null
      },
      get categoriesList() {
        return Array.from(self.categories.values())
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.categories.clear()
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      fetchJournal: flow(function* fetch() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return
        self.startLoading()
        yield apiClient.requestManager(
          () =>
            apiClient.get(
              `${CUSTOMER_PATH}/user_tutorials?generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            response.data.categories.forEach((category) => {
              self.addCategories(category)
              category.topics.forEach((topic) => {
                self.addTopics(category.id, topic)
              })
            })
            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
      }),
      sendJourneyNotification: flow(function* sendJourneyNotification(
        categoryId,
        topicId
      ) {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return
        let body = null
        if (categoryId) {
          body = { tutorial_category_id: categoryId }
        } else if (topicId) {
          body = { tutorial_topic_id: topicId }
        }
        if (!body) return
        yield apiClient.requestManager(
          () =>
            apiClient.post(
              `${CUSTOMER_PATH}/tutorial_notification?generation=${EargoGenerationService.getCurrentGeneration()}`,
              body
            ),
          () => {},
          () => {
            getRoot(self).uiStore.openNotification(
              `Error sending notification to user`,
              "error"
            )
            self.setNotFoundError()
          }
        )
      }),
      addCategories(attributes) {
        attributes = omit(attributes, ["topics"])
        attributes.id = String(attributes.id)
        self.categories.set(String(attributes.id), { ...attributes })
      },
      addTopics(categoryId, attributes) {
        attributes.id = String(attributes.id)
        const category = self.categories.get(categoryId)
        category.topics.set(String(attributes.id), { ...attributes })
      },
      reset() {
        self.state = INITIAL
        self.categories = {}
      },
    }
  })

export default JournalStore
