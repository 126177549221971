import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import colors from "styles/colors"
import {
  useConfigurationStore,
  useHearingEvaluationStore,
  useUiStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import HearingEvaluation from "components/middle_section/programs/hearing_evaluation/hearing_evaluation"
import ThresholdTile from "./threshold_tile/threshold_tile"
import { format } from "date-fns"
import { GoBackIcon } from "images/svg"
import { cloneDeep } from "lodash"

const Wrapper = styled.div`
  display: flex;
  width: 92%;
  margin: 0 auto;
  padding: 10px 0;
  ${breakpoint("mobile", "tablet")`
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  `}

  ${breakpoint("mobile")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : null}
    flex-direction: row;
  `}

  ${breakpoint("tablet")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : null}
    flex-direction: row;
  `}

  ${breakpoint("desktop")`
    ${({ isModalShown }) =>
      isModalShown
        ? `
        overflow-y: auto;
        overflow-x: hidden;
    `
        : `
        overflow-y: hidden;
        overflow-x: hidden;
    `}
    flex-direction: row;
  `}
`

const ButtonsBar = styled.div`
  width: 92%;
  height: 40px;
  margin: 0 auto;
  padding: 16px 0 16px 0;
`

const NewConfigurationButton = styled.button`
  font-family: Inter;
  font-weight: 800;
  width: 190px;
  height: 32px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.grey : colors.orange};
  border: none;
  float: right;

  &:hover {
    cursor: pointer;
  }

  ${breakpoint("mobile", "tablet")`
     width: 45%;
  `}
`

const CancelButton = styled.button`
  font-family: Inter;
  font-weight: 800;
  width: 190px;
  height: 32px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: none;
  float: right;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  ${breakpoint("mobile", "tablet")`
     margin-right: 0;
     width: 45%;
  `}
`

const GoBackButton = styled.button`
  height: 32px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: none;
  float: left;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }

  ${breakpoint("mobile")`
     margin-left: 0;
     width: 10%;
  `}
`

const LeftWrapper = styled.div`
  flex: 1.3;

  ${breakpoint("mobile", "tablet")`
    margin-bottom: 16px
  `}
`

const RightWrapper = styled.div`
  flex: 2;
`

const CreateSoundMatch = () => {
  const {
    showConfigurationModal,
    showHearingAssessmentModal,
    closeConfigurationModal,
    closeHearingAssessmentModal,
    openNotification,
    openHearingAssessmentDialog,
  } = useUiStore()
  const {
    handleApplySoundMatchFromHistory,
    profilesInNewConfiguration,
    isMaskEnv,
    applyForReset,
    setApplyForReset,
    firstApplyForNewSoundMatch,
    newDeviceConfiguration,
  } = useConfigurationStore()
  const {
    newHearingAssessment,
    setNewHearingAssessment,
    calculateBisgaardIndexForSide,
    checkIfCanSaveHearingAssessment,
    checkValidHearingAssessmentValues,
    setIsNewSoundMatch,
    addHearingEvaluationHistory,
    setEnabledConfigurationOfIndex,
    isNewSoundMatch,
    clearNewHearingAssessment,
  } = useHearingEvaluationStore()

  const cancelAndCloseModal = () => {
    if (isNewSoundMatch) {
      openHearingAssessmentDialog()
    } else {
      closeConfigurationModal()
      clearNewHearingAssessment()
      closeHearingAssessmentModal()
    }
  }

  const goBackCloseModal = () => {
    closeHearingAssessmentModal()
  }

  const [environmentalOffsets, setEnvironmentalOffsets] = useState([
    { offset: "RESTAURANT", enabled: false },
    { offset: "PHONE", enabled: false },
    { offset: "MUSIC", enabled: false },
    { offset: "TV", enabled: false },
    { offset: "MEETING", enabled: false },
  ])

  // Calculate initial Bisgaard Index for both sides
  const newObj = { ...newHearingAssessment }
  newObj.rightBisgaardIndex = calculateBisgaardIndexForSide(newObj, "right")
  newObj.leftBisgaardIndex = calculateBisgaardIndexForSide(newObj, "left")
  setNewHearingAssessment(newObj)

  useEffect(() => {
    if (
      isMaskEnv &&
      !environmentalOffsets.some((obj) => obj.offset === "MASK")
    ) {
      environmentalOffsets.push({ offset: "MASK", enabled: false })
      setEnvironmentalOffsets(environmentalOffsets)
    }
  }, [isMaskEnv])

  useEffect(() => {
    if (showHearingAssessmentModal) {
      const newObj = { ...newHearingAssessment }
      const tempEnvironmentalOffsets = cloneDeep(environmentalOffsets)
      if (profilesInNewConfiguration.length) {
        newObj["environmentalOffsets"] = []
        tempEnvironmentalOffsets.forEach((obj) => {
          if (profilesInNewConfiguration.includes(obj.offset)) {
            obj.enabled = true
          }
        })
        setNewHearingAssessment(newObj)
      }
      const currentOffsets = newHearingAssessment.environmentalOffsets
      if (currentOffsets.length) {
        tempEnvironmentalOffsets.forEach((obj) => {
          if (currentOffsets.includes(obj.offset)) {
            obj.enabled = true
          }
        })
      }
      setEnvironmentalOffsets(tempEnvironmentalOffsets)
    }
  }, [showHearingAssessmentModal])

  const onChange = async (e) => {
    const newObj = { ...newHearingAssessment }
    const { value, name } = e.target

    const key = name.split("-")[0] // key is the properties that is changing
    const side = name.split("-")[1]

    newObj.completeDate = format(new Date(), "LLL d, yyyy HH:mm:ss")

    const parsedValue = parseInt(value)
    newObj[key] = !isNaN(parsedValue) ? parsedValue : null

    if (side === "right") {
      newObj.rightBisgaardIndex = calculateBisgaardIndexForSide(newObj, side)
    } else {
      newObj.leftBisgaardIndex = calculateBisgaardIndexForSide(newObj, side)
    }
    setNewHearingAssessment(newObj)
  }

  const handleDuplicateLeftClick = () => {
    const newObj = { ...newHearingAssessment }
    Object.assign(newObj, {
      right500HzThreshold: newObj.left500HzThreshold,
      right1000HzThreshold: newObj.left1000HzThreshold,
      right2000HzThreshold: newObj.left2000HzThreshold,
      right3000HzThreshold: newObj.left3000HzThreshold,
      right4000HzThreshold: newObj.left4000HzThreshold,
      rightBisgaardIndex: newObj.leftBisgaardIndex,
    })
    setNewHearingAssessment(newObj)
  }

  const handleApplyNewHearingAssessment = () => {
    if (checkValidHearingAssessmentValues) {
      return openNotification(
        "Fields cannot be empty",
        "error"
      )
    } else if (checkIfCanSaveHearingAssessment) {
      return openNotification(
        "Can't find best fit for the provided values, please change them",
        "error"
      )
    } else {
      if (applyForReset) {
        setApplyForReset(false)
      }
      const newObj = { ...newHearingAssessment }
      setIsNewSoundMatch(true)
      addHearingEvaluationHistory(newObj)
      setEnabledConfigurationOfIndex(0)
      handleApplySoundMatchFromHistory()
      firstApplyForNewSoundMatch(newDeviceConfiguration)
      closeHearingAssessmentModal()
    }
  }

  return (
    <>
      <ButtonsBar>
        <NewConfigurationButton onClick={handleApplyNewHearingAssessment}>
          Enable
        </NewConfigurationButton>
        <CancelButton onClick={cancelAndCloseModal}>Cancel</CancelButton>
        <GoBackButton onClick={goBackCloseModal}>
          <GoBackIcon />
        </GoBackButton>
      </ButtonsBar>
      <Wrapper isModalShown={showConfigurationModal ? 1 : 0}>
        <LeftWrapper>
          <HearingEvaluation />
        </LeftWrapper>
        <RightWrapper>
          <ThresholdTile
            onChange={onChange}
            environmentalOffsetsList={environmentalOffsets}
            handleDuplicateLeftClick={handleDuplicateLeftClick}
          />
        </RightWrapper>
      </Wrapper>
    </>
  )
}

export default observer(CreateSoundMatch)
