import React from "react"
import { Modal } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import {
  useUiStore,
  useHearingEvaluationStore,
  useConfigurationStore,
  useLogsStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import Programs from "../../middle_section/programs/programs"
import CreateSoundMatch from "../../middle_section/create_sound_match/create_sound_match"

const ModalContent = styled.div`
  outline: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 95vh;
  max-width: 1200px;
  background: ${colors.lightGrey};
  margin-bottom: 25px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 991px) {
    min-width: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-height: calc(100vh - 69px);
  }
  @media (min-width: 992px) {
    top: 50%;
    min-width: calc(100% - 100px);
    transform: translate(-50%, -50%);
  }
`

const NewConfigurationModal = () => {
  const {
    showConfigurationModal,
    closeConfigurationModal,
    closeHearingAssessmentModal,
    showHearingAssessmentModal,
  } = useUiStore()

  const {
    clearNewHearingAssessment,
    clearHearingEvaluationValues,
  } = useHearingEvaluationStore()

  const { clearDeviceConfigurationValues } = useConfigurationStore()
  const { clearLogsValues } = useLogsStore()

  const handleClose = () => {
    closeConfigurationModal()
    closeHearingAssessmentModal()
    clearNewHearingAssessment()
    clearHearingEvaluationValues()
    clearDeviceConfigurationValues()
    clearLogsValues()
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showConfigurationModal}
      closeAfterTransition
      onClose={handleClose}
    >
      <ModalContent>
        {showHearingAssessmentModal ? <CreateSoundMatch /> : <Programs />}
      </ModalContent>
    </Modal>
  )
}

export default observer(NewConfigurationModal)
